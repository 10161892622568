/* Author: David Clough */
jQuery.fn.exists = function () {
	return this.length > 0;
}
// IE8 ployfill for GetComputed Style (for Responsive Script below)
//if(!window.getComputedStyle){window.getComputedStyle=function(e,t){this.el=e;this.getPropertyValue=function(t){var n=/(\-([a-z]){1})/g;if(t=="float")t="styleFloat";if(n.test(t)){t=t.replace(n,function(){return arguments[2].toUpperCase()})}return e.currentStyle[t]?e.currentStyle[t]:null};return this}}
/* FOR DEV ONLY*/
if (typeof console === "undefined") {
	console = {};
	console.log = function () {
		return;
	}
}


// as the page loads, call these scripts
jQuery(document).ready(function ($) {

	var slideout = new Slideout({
		'panel': document.getElementById('panel'),
		'menu': document.getElementById('menu'),
		'padding': 256,
		'tolerance': 70
	});

	$('.hamburger--js').on('click', function (e) {
		slideout.toggle();
		$(this).toggleClass('is-active');
		e.preventDefault();
	});

	var $main__menu = $('#menu-main');
	var $menu__toggle = $('.toggle_menu--js');
	var $menu__children = $('.dropdown-menu');
	var $blog__listings = $('.blog__listings');

	if ($('.blog__listings').exists()) {

		$blog__listings.on('click', 'i', function () {

			$blog__listings.find('i').show();
			$blog__listings.find('.entry-summary').slideUp();

			var $article = $('#' + $(this).attr('data-post_id'));
			$article.find('.entry-summary').slideDown();
			$article.find('i').hide();

		});

	}

	$($menu__toggle).on('click',
		function (e) {
			$(this).closest('.dropdown').find('ul').toggleClass('shrink');
		});


	if ($('.home-slide').exists()) {
		$('.home-slide').flexslider({
			animation: "fade", //String: Select your animation type, "fade" or "slide"

			// Usability features
			pauseOnAction: true, //Boolean: Pause the slideshow when interacting with control elements, highly recommended.
			pauseOnHover: false, //Boolean: Pause the slideshow when hovering over slider, then resume when no longer hovering
			useCSS: true, //{NEW} Boolean: Slider will use CSS3 transitions if available
			touch: true, //{NEW} Boolean: Allow touch swipe navigation of the slider on touch-enabled devices

			// Primary Controls
			controlNav: false, //Boolean: Create navigation for paging control of each clide? Note: Leave true for manualControls usage
			directionNav: true, //Boolean: Create navigation for previous/next navigation? (true/false)
			prevText: "Previous", //String: Set the text for the "previous" directionNav item
			nextText: "Next", //String: Set the text for the "next" directionNav item

			// Secondary Navigation
			keyboard: true //Boolean: Allow slider navigating via keyboard left/right keys

		});
	}

	if ($('.history-slide').exists()) {
		$('.history-slide').flexslider({
			animation: "fade", //String: Select your animation type, "fade" or "slide"

			// Usability features
			pauseOnAction: true, //Boolean: Pause the slideshow when interacting with control elements, highly recommended.
			pauseOnHover: false, //Boolean: Pause the slideshow when hovering over slider, then resume when no longer hovering
			useCSS: true, //{NEW} Boolean: Slider will use CSS3 transitions if available
			touch: true, //{NEW} Boolean: Allow touch swipe navigation of the slider on touch-enabled devices

			// Primary Controls
			controlNav: false, //Boolean: Create navigation for paging control of each clide? Note: Leave true for manualControls usage
			directionNav: true, //Boolean: Create navigation for previous/next navigation? (true/false)
			prevText: "Previous", //String: Set the text for the "previous" directionNav item
			nextText: "Next", //String: Set the text for the "next" directionNav item

			// Secondary Navigation
			keyboard: true //Boolean: Allow slider navigating via keyboard left/right keys
		});
	}


	if ($('.hlimages').exists()) {
		$('.hlimages').flexslider({
			animation: "fade", //String: Select your animation type, "fade" or "slide"

			// Usability features
			pauseOnAction: true, //Boolean: Pause the slideshow when interacting with control elements, highly recommended.
			pauseOnHover: false, //Boolean: Pause the slideshow when hovering over slider, then resume when no longer hovering
			useCSS: true, //{NEW} Boolean: Slider will use CSS3 transitions if available
			touch: true, //{NEW} Boolean: Allow touch swipe navigation of the slider on touch-enabled devices

			// Primary Controls
			controlNav: false, //Boolean: Create navigation for paging control of each clide? Note: Leave true for manualControls usage
			directionNav: true, //Boolean: Create navigation for previous/next navigation? (true/false)
			prevText: "Previous", //String: Set the text for the "previous" directionNav item
			nextText: "Next", //String: Set the text for the "next" directionNav item

			// Secondary Navigation
			keyboard: true //Boolean: Allow slider navigating via keyboard left/right keys
		});

	}


	if ($('.floorplan').exists()) {
		$('.floorplan a').on('click',
			function (e) {
				e.preventDefault(); // prevent the default action
				e.stopPropagation(); // stop the click from bubbling
				$('.images a').removeClass('link-active');
				$(this).addClass('link-active');
				$(".hlimages").hide();
				$(".hlfloorplan").show();
			});

	}
	if ($('.images').exists()) {
		$('.images a').on('click',
			function (e) {
				e.preventDefault(); // prevent the default action
				e.stopPropagation(); // stop the click from bubbling
				$('.floorplan a').removeClass('link-active');
				$(this).addClass('link-active');
				$('.hlfloorplan').hide();
				$('.hlimages').show();
			});

	}

}); /* end of as page load scripts */